import { useEffect, useState } from "react";
import { gifter_url, amazon_s3_logo } from "../../services/Services";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faCircle } from "@fortawesome/free-solid-svg-icons";

export default function SearchRow({ sr, idx }) {
  const [num, setNum] = useState(0);

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    setNum(randomNumberInRange(1, 5));
  }, []);

  const [desc, setDesc] = useState(
    sr.company_description !== ""
      ? sr.company_description.length > 50
        ? sr.company_description.substring(0, 50) + " ..."
        : sr.company_description
      : ""
  );

  const handleText = () => {
    setDesc(sr.company_description);
  };

  return (
    <div className="col-12 col-md-4 my-2">
      <div className="mx-2 shadow-lg border-2 bgc-white rounded">
        <a
          href={gifter_url + sr.id}
          className="col-md-6 align-items-start company-results-link my-2 py-3"
        >
          <div className="w-25 justify-content-start align-top d-inline-block">
            {sr.logo === null ? (
              <img
                src={"img/cards/search/gift-card-" + num + ".jpg"}
                className="card-img-top rounded company-results-logo img-fluid mx-2 my-2"
                style={{ border: "none" }}
                alt="logo"
              />
            ) : (
              <img
                src={amazon_s3_logo + "/" + sr.logo}
                className="card-img-top rounded company-results-logo img-fluid mx-2 my-2"
                style={{ border: "none" }}
                alt="logo"
              />
            )}
          </div>
          <div className="w-50 justify-content-start align-top d-inline-block">
            <div className="ms-3 me-1 my-1" style={{ textAlign: "start" }}>
              <p
                className="fw-bold search-company-name"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  marginBottom: "2px",
                }}
              >
                {sr.company_name}
              </p>
              <p
                style={{
                  marginBottom: "2px",
                  fontSize: "12px",
                }}
              >
                {sr.address1},
                <br />
                {sr.address2 ? sr.address2 + "<br/>" : ""}
                {sr.city}, <br /> {sr.state} - {sr.zip}
              </p>
            </div>
          </div>
          <div className="w-25 justify-content-start d-inline-block clearfix">
            <div className="mx-4 my-4 float-end1">
              <span className="fa-layers fa-1x">
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{ color: "#4548A2" }}
                  transform={"grow-14"}
                />
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  transform={"grow-4"}
                  style={{
                    width: "12px",
                    height: "12px",
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </span>
            </div>
          </div>
        </a>
        <div className="w-100 justify-content-start align-top d-inline-block">
          <div className="ms-3 me-1 mt-0 mb-2" style={{ textAlign: "start" }}>
            <p onClick={handleText} className="search-company-desc">
              {desc}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
