import axios from "axios";
import { subHours } from "date-fns";

const api_url = process.env.REACT_APP_GIFTER_API_URL;
export const gifter_site_url = process.env.REACT_APP_GIFTER_URL;
export const amazon_s3_logo = process.env.REACT_APP_GIFTER_AMAZON_S3;
export const gifter_stripe_api_key =
  process.env.REACT_APP_GIFTER_STRIPE_API_KEY;
export const gifter_url = gifter_site_url + "/gifter-details/";
export const giftcard_url = gifter_site_url + "/giftcard-details/";
export const payment_success_url = gifter_site_url + "/payment-success/";

export const getFormattedDate = (dateStr) => {
  const date = subHours(new Date(dateStr), 5);
  return date.toLocaleString("en-US", { timeZone: "EST" });
};

//CompanyUserRegistration.js
export function createCompanyAndUser(data, signal) {
  return fetch(api_url + "/api/v1/business/create-company-user", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//CompanyUsersEdit.js
export function createAdditionalUsers(data, signal) {
  return fetch(api_url + "/api/v1/user/create_additonal_users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//CompanyUsersEdit.js
export function getAllUsers(token, business_id, signal) {
  return fetch(api_url + "/api/v1/user/" + business_id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "api-token": token,
    },
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//CompanyUsersEdit.js
export function deleteUser(token, user_id, signal) {
  return fetch(api_url + "/api/v1/user/delete/" + user_id, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "api-token": token,
    },
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//CompanyUsersEdit.js
export function getUser(token, user_id, signal) {
  return fetch(api_url + "/api/v1/user/get_user_by_id/" + user_id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "api-token": token,
    },
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//CompanyUsersEdit.js
export function updateUser(token, user_id, data, signal) {
  return fetch(api_url + "/api/v1/user/update_user/" + user_id, {
    method: "PUT",
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//UserLogin.js
export function login(data, signal) {
  return fetch(api_url + "/api/v1/user/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//ForgotPassword.js
export function forgotPassword(data, signal) {
  return fetch(api_url + "/api/v1/user/forgot_password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//ResetPassword.js
export function resetPassword(data, signal) {
  return fetch(api_url + "/api/v1/user/reset_password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//ActivateUserAccount.js
export function activateUser(data, signal) {
  return fetch(api_url + "/api/v1/user/activate_account", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//CompanyProfileEdit.js, CompanyBankEdit.js, DisplayQRCode.js, Settings.js
export function getCompanyById(business_id, signal) {
  return fetch(api_url + "/api/v1/business/by_id/" + business_id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//CompanyProfileEdit.js,
export function updateProfile(token, data, business_id, user_id, signal) {
  return fetch(
    api_url + "/api/v1/business/profile_update/" + business_id + "/" + user_id,
    {
      method: "PUT",
      headers: {
        "api-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      signal: signal,
    }
  ).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//CompanyBankEdit.js
export function updateCompany(token, data, id, signal) {
  return fetch(api_url + "/api/v1/business/" + id, {
    method: "PUT",
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//CompanyProfileEdit.js
export function getUserById(token, user_id, signal) {
  return fetch(api_url + "/api/v1/user/get_user_by_id/" + user_id, {
    method: "GET",
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//UploadLogos.js
export function uploadLogo(token, formData, signal) {
  const url = api_url + "/api/v1/business/upload_logo";
  const config = {
    headers: {
      "api-token": token,
      "content-type": "multipart/form-data",
    },
    signal: signal,
  };
  return axios.post(url, formData, config);
}

//GifterDetails.js
export function createGifter(data, signal) {
  return fetch(api_url + "/api/v1/gifter/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//GiftCardDetails.js
export function getGiftCard(id, signal) {
  return fetch(api_url + "/api/v1/giftcard/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//GiftCardCode.js
export function getGiftCardByCode(code, token, signal) {
  return fetch(api_url + "/api/v1/giftcard/by_code/" + code, {
    method: "GET",
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//GiftCardRedeem.js
export function getGiftCardById(id, token, signal) {
  return fetch(api_url + "/api/v1/giftcard/by_id/" + id, {
    method: "GET",
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//GiftCardRedeem.js
export function redeemGiftCard(giftcard_id, data, token, signal) {
  return fetch(api_url + "/api/v1/giftcard/redeem/" + giftcard_id, {
    method: "PUT",
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//GiftCardRedeem.js
export function getAllTransactions(giftcard_code, token, signal) {
  return fetch(
    api_url + "/api/v1/transaction/get_all_transaction/" + giftcard_code,
    {
      method: "GET",
      headers: {
        "api-token": token,
        "Content-Type": "application/json",
      },
      signal: signal,
    }
  ).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//CCPayment.js
export function createPaymentIntent(data, signal) {
  return fetch(api_url + "/api/v1/gifter/create-payment-intent", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => res.json());
}

//CCPaymentSuccess.js
export function updateGiftcard(giftcard_id, data, signal) {
  return fetch(api_url + "/api/v1/giftcard/" + giftcard_id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//CCPaymentConfirmation.js
export function getConfirmationForGiftCard(giftcard_id, signal) {
  return fetch(api_url + "/api/v1/giftcard/confirm/" + giftcard_id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

export function getAllGiftCardsByDate(
  token,
  business_id,
  start_date,
  end_date,
  signal
) {
  return fetch(
    api_url +
      "/api/v1/giftcard/get_all_gc_and_transactions_by_date/" +
      business_id +
      "?start_date=" +
      start_date +
      "&end_date=" +
      end_date,
    {
      method: "GET",
      headers: {
        "api-token": token,
        "Content-Type": "application/json",
      },
      signal: signal,
    }
  ).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

export function getRemainingAndRedeemedAmount(
  token,
  business_id,
  start_date,
  end_date,
  signal
) {
  return fetch(
    api_url +
      "/api/v1/giftcard/get_transaction_summary_by_date_for_web/" +
      business_id +
      "?start_date=" +
      start_date +
      "&end_date=" +
      end_date,
    {
      method: "GET",
      headers: {
        "api-token": token,
        "Content-Type": "application/json",
      },
      signal: signal,
    }
  ).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

export function getAllTransactionsByBusinessId(
  token,
  business_id,
  start_date,
  end_date,
  signal
) {
  return fetch(
    api_url +
      "/api/v1/giftcard/get_all_transactions/" +
      business_id +
      "?start_date=" +
      start_date +
      "&end_date=" +
      end_date,
    {
      method: "GET",
      headers: {
        "api-token": token,
        "Content-Type": "application/json",
      },
      signal: signal,
    }
  ).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

export function getAllPayoutsByDate(
  token,
  business_id,
  start_date,
  end_date,
  signal
) {
  return fetch(
    api_url +
      "/api/v1/payout/get_all_payout/" +
      business_id +
      "?start_date=" +
      start_date +
      "&end_date=" +
      end_date,
    {
      method: "GET",
      headers: {
        "api-token": token,
        "Content-Type": "application/json",
      },
      signal: signal,
    }
  ).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

export function getDataForGraph(
  token,
  business_id,
  start_date,
  end_date,
  signal
) {
  return fetch(
    api_url +
      "/api/v1/giftcard/get_data_for_graph_by_date/" +
      business_id +
      "?start_date=" +
      start_date +
      "&end_date=" +
      end_date,
    {
      method: "GET",
      headers: {
        "api-token": token,
        "Content-Type": "application/json",
      },
      signal: signal,
    }
  ).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//SearchResults.js
export function getCompaniesList(data, signal) {
  return fetch(api_url + "/api/v1/business/search_companies", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}

//AdminSearchResults.js
export function getCompanyForAdmin(data, signal) {
  return fetch(api_url + "/api/v1/business/search_companies_for_admin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    signal: signal,
  }).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    } else if (res.status === 400) {
      return res.json();
    } else {
      throw Error(res.statusText);
    }
  });
}
