import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "../common/Header";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { login } from "../services/Services";
import Footer from "../common/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function UserLogin() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Must be a valid email"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at 6 char long"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    const rem_flag = localStorage.getItem("remember_me");
    if (rem_flag === "true") {
      setValue("email", localStorage.getItem("user_email"));
      setValue("remember_me", true);
    }
  }, [setValue]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onSubmit = (data, e) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const rem_me = data["remember_me"];
    delete data["remember_me"];
    login(data, signal)
      .then((res) => {
        if (res.jwt_token) {
          localStorage.setItem("token", res.jwt_token);
          localStorage.setItem("user_id", res.user_id);
          if (rem_me === true) {
            localStorage.setItem("user_email", res.user_email);
            localStorage.setItem("remember_me", "true");
          } else {
            localStorage.setItem("user_email", "");
            localStorage.setItem("remember_me", "false");
          }
          localStorage.setItem("role", res.role);
          localStorage.setItem("user_name", res.user_name);
          localStorage.setItem("business_id", res.business_id);
          localStorage.setItem("business_name", res.business_name);
          localStorage.setItem("account_number", res.account_number);
          localStorage.setItem("logged_in", "Y");
          reset();
          if (res.role === "2") {
            navigate("/dashboard");
          } else if (res.role === "3") {
            navigate("/redeem");
          } else if (res.role === "1") {
            navigate("/admin-search");
          } else navigate("/404-error-page");
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup
    return () => controller.abort();
  };

  const clickRegister = () => {
    navigate("/company-user-reg");
  };

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container text-center px-5 py-5 div-bank-info">
          <div className="row row-cols-2 align-items-center g-4 py-5">
            <div className="offset-md-4 col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-start">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row row-cols-1 align-items-center g-4 py-3 text-center">
                  <div className="col">
                    <h2 className="gifter-details-header1">sign in</h2>
                  </div>
                </div>
                <div className="row mb-2">
                  <h4 className="gifter-details-header2">
                    login to your account
                  </h4>
                </div>
                {/*  email address */}
                <div className={"input-group " + (errors.email ? "" : "mb-2")}>
                  <div className="row form-floating">
                    <input
                      type="text"
                      id="email1"
                      name="email"
                      placeholder="Email"
                      autoComplete="on"
                      className={
                        "form-control " + (errors.email ? "is-invalid" : "")
                      }
                      {...register("email")}
                    />
                    <label htmlFor="email1">EMail</label>
                  </div>
                </div>
                {errors.email?.message && (
                  <div className="row align-items-start text-start mb-1">
                    <span className="span-errors" role="alert">
                      {errors.email.message}
                    </span>
                  </div>
                )}

                {/*  password */}
                <div
                  className={"input-group " + (errors.password ? "" : "mb-2")}
                >
                  <div className="row form-floating">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password1"
                      name="password"
                      placeholder="Password"
                      className={
                        "form-control " + (errors.password ? "is-invalid" : "")
                      }
                      {...register("password")}
                    />
                    <label htmlFor="password1">Password</label>
                  </div>
                  <span
                    className="row1 input-group-text span-eye-pwd user-reg-eye-pwd"
                    id="password"
                  >
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEyeSlash : faEye}
                      onClick={togglePasswordVisibility}
                    />
                  </span>
                </div>
                {errors.password?.message && (
                  <div className="row align-items-start text-start mb-1">
                    <span className="span-errors" role="alert">
                      {errors.password.message}
                    </span>
                  </div>
                )}

                <div className="row mb-3">
                  <div className="col-6 text-start">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      aria-label="Checkbox for following text input"
                      id="remChk"
                      {...register("remember_me")}
                    />
                    <label
                      className="form-check-label small-text"
                      htmlFor="remChk"
                    >
                      &nbsp;Remember Me
                    </label>
                  </div>
                  <div className="col-6 text-end">
                    <a href="/forgot-password" className="small-text">
                      Forgot password?
                    </a>
                  </div>
                </div>

                {/* submit */}
                <div className="row mb-1 text-center">
                  <div className="col-12 mb-3">
                    <button
                      type="submit"
                      className="btn btn-light navbar-button"
                    >
                      Login
                    </button>
                  </div>
                </div>
                <div className="row mb-2 text-center">
                  <div className="col-12">
                    <p className="small fw-bold mt-2 pt-1 mb-0">
                      Don't have an account?{" "}
                      <a
                        href="#!"
                        className="link-danger"
                        onClick={clickRegister}
                      >
                        Register
                      </a>
                    </p>
                  </div>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
