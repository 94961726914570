import { useForm } from "react-hook-form";
import { useState } from "react";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { statesList } from "../services/Data";

import { createCompanyAndUser } from "../services/Services";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import Header from "../common/Header";
import Footer from "../common/Footer";
import CompanyUserAgreement from "./CompanyUserAgreement";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function CompanyUserRegistration() {
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [selState, setSelState] = useState("");
  const [selStateStyle, setSelStateStyle] = useState("");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [formData, setFormData] = useState();

  const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
  };

  const formSchema = Yup.object().shape({
    //company
    company_name: Yup.string().required("Company Name is required"),
    category: Yup.string().required("Category is required"),
    address1: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip: Yup.string().required("Zip Code is required"),
    //user
    primary_contact: Yup.string().required("Business Contact is required"),
    phone: Yup.string().required("Phone is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Must be a valid email"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at 8 char long")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/^(?=.*[!@#$%^&*])/, getCharacterValidationError("special")),
    confirmPwd: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const formOptions = {
    resolver: yupResolver(formSchema),
  };

  const {
    register,
    handleSubmit,
    reset,
    // setValue,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = async (data, e) => {
    setShow(true);
    delete data["confirmPwd"];
    setFormData(data);
  };

  const createCompany = (data) => {
    data["role"] = "2"; //main business user
    const controller = new AbortController();
    const signal = controller.signal;
    createCompanyAndUser(data, signal)
      .then((res) => {
        setError(false);
        if (res.jwt_token) {
          localStorage.setItem("token", res.jwt_token);
          localStorage.setItem("business_id", res.business_id);
          localStorage.setItem("user_id", res.user_id);
          localStorage.setItem("user_name", res.user_name);
          localStorage.setItem("logged_in", "Y");
          reset();
          navigate("/company-reg-complete");
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup
    return () => controller.abort();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleAccept = () => {
    createCompany(formData);
    setShow(false);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container text-center px-5 pt-5">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="mx-auto p-2">
                <p className="fs-6 fw-bold pb-1 search-small">
                  Join our community of supporters.
                </p>
                <p className="fs-2 fw-bolder  pb-0 search-big">
                  business sign up
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container col-xxl-5 col-lg-6 col-md-8 col-12 px-4 py-3 div-bank-info text-start">
          <div className="row row-cols-1 align-items-center g-4 py-3">
            <div className="offset-md-0 col-12 text-start">
              <form onSubmit={handleSubmit(onSubmit)} className="form-floating">
                <div className="row mb-2">
                  <h4 className="gifter-details-header2">
                    Business information
                  </h4>
                </div>
                {/* company name */}
                <div
                  className={
                    "row form-floating " + (errors.company_name ? "" : "mb-3")
                  }
                >
                  <input
                    type="text"
                    id="company_name"
                    placeholder="Business Name"
                    className={
                      "form-control " +
                      (errors.company_name ? "is-invalid" : "")
                    }
                    {...register("company_name")}
                  />
                  <label htmlFor="company_name">Business Name *</label>
                </div>
                {errors.company_name?.message && (
                  <div className="row align-items-start text-start mb-3">
                    <span className="span-errors" role="alert">
                      {errors.company_name.message}
                    </span>
                  </div>
                )}

                {/* address 1  */}
                <div
                  className={
                    "row form-floating " + (errors.address1 ? "" : "mb-3")
                  }
                >
                  <input
                    type="text"
                    id="address1"
                    placeholder="Address *"
                    className={
                      "form-control " + (errors.address1 ? "is-invalid" : "")
                    }
                    {...register("address1")}
                  />
                  <label htmlFor="address1">Address *</label>
                </div>
                {errors.address1?.message && (
                  <div className=" align-items-start text-start mb-3">
                    <span className="span-errors" role="alert">
                      {errors.address1.message}
                    </span>
                  </div>
                )}

                {/* address 2  */}
                <div className={"row form-floating mb-3"}>
                  <input
                    type="text"
                    id="address2"
                    placeholder="Address"
                    className="form-control"
                    {...register("address2")}
                  />
                  <label htmlFor="address2">Address</label>
                </div>

                <div className="row gy-0">
                  {/* city   */}
                  <div
                    className={
                      "col-6 form-floating g-0 " + (errors.city ? "" : "mb-3")
                    }
                  >
                    <input
                      type="text"
                      id="city"
                      placeholder="City *"
                      className={
                        "form-control no-curve-border-right " +
                        (errors.city ? "is-invalid" : "")
                      }
                      {...register("city")}
                    />
                    <label htmlFor="city">City *</label>
                  </div>

                  {/* state   */}
                  <div
                    className={
                      "col-3 form-floating g-0 " + (errors.state ? "" : "mb-3")
                    }
                  >
                    <select
                      id="state"
                      placeholder=""
                      className={
                        "form-select no-curve-border " +
                        (errors.state ? "is-invalid" : "")
                      }
                      aria-label="State"
                      defaultValue={selState}
                      {...register("state", {
                        onChange: (e) => {
                          setSelStateStyle("after-select");
                        },
                      })}
                    >
                      <option value=""></option>
                      {statesList.map((x, index) => (
                        <option key={index} value={x.value}>
                          {x.name}
                        </option>
                      ))}
                    </select>
                    <label
                      htmlFor="state"
                      className={"spl-label-select " + selStateStyle}
                    >
                      State *
                    </label>
                  </div>

                  {/* zip code   */}
                  <div
                    className={
                      "col-3 form-floating g-0 " + (errors.zip ? "" : "mb-3")
                    }
                  >
                    <input
                      type="text"
                      id="zip"
                      placeholder="Zip Code *"
                      className={
                        "form-control no-curve-border-left " +
                        (errors.zip ? "is-invalid" : "")
                      }
                      {...register("zip")}
                    />
                    <label htmlFor="zip">Zip Code *</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    {errors.city?.message && (
                      <div className=" align-items-start text-start mb-3">
                        <span className="span-errors" role="alert">
                          {errors.city.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-3">
                    {errors.state?.message && (
                      <div className=" align-items-start text-start mb-3">
                        <span className="span-errors" role="alert">
                          {errors.state.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-3">
                    {errors.zip?.message && (
                      <div className=" align-items-start text-start mb-3">
                        <span className="span-errors" role="alert">
                          {errors.zip.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                {/* category  */}
                <div
                  className={
                    "row form-floating " + (errors.category ? "" : "mb-3")
                  }
                >
                  <input
                    type="text"
                    id="category"
                    placeholder="Business Category *"
                    className={
                      "form-control " + (errors.category ? "is-invalid" : "")
                    }
                    {...register("category")}
                  />
                  <label htmlFor="category">Business Category *</label>
                </div>
                {errors.category?.message && (
                  <div className=" align-items-start text-start mb-3">
                    <span className="span-errors" role="alert">
                      {errors.category.message}
                    </span>
                  </div>
                )}

                {/* company description  */}
                <div className={"row form-floating mb-3"}>
                  <textarea
                    id="company_description"
                    style={{ height: "75px" }}
                    placeholder="Business Description"
                    className="form-control"
                    {...register("company_description")}
                    maxLength="128"
                  />
                  <label htmlFor="company_description">
                    Business Description
                  </label>
                </div>

                <div className="row mt-4 mb-2">
                  <h4 className="gifter-details-header2">Business contact</h4>
                </div>

                {/* Business contact */}
                <div
                  className={
                    "row form-floating " +
                    (errors.primary_contact ? "" : "mb-3")
                  }
                >
                  <input
                    type="text"
                    id="primary_contact"
                    placeholder="Primary Business Contact"
                    className={
                      "form-control " +
                      (errors.primary_contact ? "is-invalid" : "")
                    }
                    {...register("primary_contact")}
                  />
                  <label htmlFor="primary_contact">
                    Primary Business Contact *
                  </label>
                </div>
                {errors.primary_contact?.message && (
                  <div className="row align-items-start text-start mb-3">
                    <span className="span-errors" role="alert">
                      {errors.primary_contact.message}
                    </span>
                  </div>
                )}

                {/* contact phone*/}
                <div
                  className={
                    "row form-floating " + (errors.phone ? "" : "mb-3")
                  }
                >
                  <InputMask
                    type="text"
                    id="phone"
                    mask="(999)999-9999"
                    placeholder="Phone Number"
                    autoComplete="on"
                    className={
                      "form-control " + (errors.phone ? "is-invalid" : "")
                    }
                    {...register("phone")}
                  />
                  <label htmlFor="phone">Phone Number *</label>
                </div>
                {errors.phone?.message && (
                  <div className="row align-items-start text-start mb-3">
                    <span className="span-errors" role="alert">
                      {errors.phone.message}
                    </span>
                  </div>
                )}

                {/*  email address */}
                <div
                  className={
                    "row form-floating " + (errors.email ? "" : "mb-3")
                  }
                >
                  <input
                    type="text"
                    id="email"
                    placeholder="Business Name"
                    autoComplete="on"
                    className={
                      "form-control " + (errors.email ? "is-invalid" : "")
                    }
                    {...register("email")}
                  />
                  <label htmlFor="email">
                    Email Address * (your account login)
                  </label>
                </div>
                {errors.email?.message && (
                  <div className="row align-items-start text-start mb-3">
                    <span className="span-errors" role="alert">
                      {errors.email.message}
                    </span>
                  </div>
                )}

                {/*  password */}
                <div
                  className={"input-group " + (errors.password ? "" : "mb-3")}
                >
                  <div className=" row form-floating">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password1"
                      name="password"
                      placeholder="Password"
                      className={
                        "form-control " + (errors.password ? "is-invalid" : "")
                      }
                      {...register("password")}
                    />
                    <label htmlFor="password1">Password *</label>
                  </div>
                  <span
                    className="row1 input-group-text span-eye-pwd user-reg-eye-pwd"
                    id="password"
                  >
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEyeSlash : faEye}
                      onClick={togglePasswordVisibility}
                    />
                  </span>
                </div>
                {errors.password?.message && (
                  <div className="row align-items-start text-start mb-1">
                    <span className="span-errors" role="alert">
                      {errors.password.message}
                    </span>
                  </div>
                )}

                {/*  confirm password */}
                <div
                  className={"input-group " + (errors.confirmPwd ? "" : "mb-3")}
                >
                  <div className=" row form-floating">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password2"
                      name="confirmPwd"
                      placeholder="Confirm Password"
                      className={
                        "form-control " +
                        (errors.confirmPwd ? "is-invalid" : "")
                      }
                      {...register("confirmPwd")}
                    />
                    <label htmlFor="password2">Confirm Password *</label>
                  </div>
                  <span
                    className="row1 input-group-text span-eye-pwd user-reg-eye-pwd"
                    id="password2"
                  >
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEyeSlash : faEye}
                      onClick={togglePasswordVisibility}
                    />
                  </span>
                </div>
                {errors.confirmPwd?.message && (
                  <div className="row align-items-start text-start mb-3">
                    <span className="span-errors" role="alert">
                      {errors.confirmPwd.message}
                    </span>
                  </div>
                )}

                <div className="row mb-2">
                  <div className="col-12">
                    <ul>
                      <li>Password must be minimum 8 characters in length</li>
                      <li>
                        Must contain Uppcase (A-Z), Lowercase (a-z), Numbers
                        (0-9) and Non-alphanumberic numbers (example: !,#, $, %)
                      </li>
                    </ul>
                  </div>
                </div>

                {/* submit */}
                <div className="row mb-2 pt-3 text-center">
                  <div className="col-12 my-3">
                    <button
                      type="submit"
                      className="btn btn-light navbar-button"
                    >
                      SUBMIT
                    </button>
                    <CompanyUserAgreement
                      handleAccept={handleAccept}
                      show={show}
                      handleClose={handleClose}
                    />
                  </div>
                </div>

                {error && (
                  <div className="row mb-2">
                    <div
                      className="alert alert-danger text-center"
                      role="alert"
                    >
                      {message}
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
