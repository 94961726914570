import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCompanyById,
  updateProfile,
  getUserById,
} from "../services/Services";
import { statesList } from "../services/Data";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Header from "../common/Header";
import Footer from "../common/Footer";
import SideNavbar from "./dashboard/SideNavbar";

function CompanyProfileEdit() {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const business_id = localStorage.getItem("business_id");
  const user_id = localStorage.getItem("user_id");

  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [edit, setEdit] = useState(false);

  const [selState, setSelState] = useState("");
  const [selStateStyle, setSelStateStyle] = useState("");
  const [emailId, setEmailId] = useState("");

  const formSchema = Yup.object().shape(
    {
      //business
      company_name: Yup.string().required("Business Name is required"),
      ein: Yup.string().nullable().required("EIN Number is required"),
      address1: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      zip: Yup.string().required("Zip Code is required"),
      category: Yup.string().required("Category is required"),
      //user
      primary_contact: Yup.string().required("Business Contact is required"),
      phone: Yup.string().required("Phone is required"),
    },
    [
      ["company_name", "company_name"],
      ["ein", "ein"],
      ["address1", "address1"],
      ["city", "city"],
      ["state", "state"],
      ["zip", "zip"],
      ["category", "category"],
      ["company_description", "company_description"],
      ["contact", "contact"],
      ["phone", "phone"],
    ]
  );

  const formOptions = {
    resolver: yupResolver(formSchema),
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getCompanyById(business_id, signal)
      .then((res) => {
        if (JSON.stringify(res) !== "{}") {
          setValue("company_name", res.company_name);
          setValue("ein", res.ein);
          setValue("address1", res.address1);
          setValue("address2", res.address2);
          setValue("city", res.city);
          setValue("state", res.state);
          setSelStateStyle("after-select");
          setValue("zip", res.zip);
          setValue("category", res.category);
          setValue("company_description", res.company_description);
          setSelState(res.state);
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    getUserById(token, user_id, signal)
      .then((res) => {
        if (JSON.stringify(res) !== "{}") {
          setValue("primary_contact", res.primary_contact);
          setEmailId(res.email);
          setValue("phone", res.phone);
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  }, [business_id, setValue, token, user_id]);

  const onSubmit = async (data, e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    updateProfile(token, data, business_id, user_id, signal)
      .then((res) => {
        if (!res.error) {
          setMessage("Business Information has been updated successfully.");
          setUpdated(true);
          setTimeout(() => {
            reset();
            navigate("/dashboard");
          }, 2000);
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  };

  const handleEdit = () => {
    setEdit(true);
  };

  return (
    <div className="container-fluid navbar-hero-div">
      <Header />
      <div className="container text-center px-5 py-2 div-bank-info">
        <div className="row row-cols-2 align-items-start g-4 py-3">
          <div className="col-3">
            <SideNavbar />
          </div>
          <div className="offset-md-0 col-9 text-start py-3 px-4 dashboard-bg-border">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="form-floating px-5 py-5"
            >
              <div className="row mb-2">
                <div className="col-6 float-start">
                  {/* Bank information */}
                  <h4 className="gifter-details-header2">
                    Business information
                  </h4>
                </div>
                <div className="col-6 text-end">
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    className="text-primary company-profile-edit"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    onClick={handleEdit}
                  />
                </div>
              </div>

              {/* company name */}
              <div
                className={
                  "row form-floating " + (errors.company_name ? "" : "mb-3")
                }
              >
                <input
                  type="text"
                  id="company_name"
                  placeholder="Business Name"
                  disabled={!edit ? "disabled" : ""}
                  className={
                    "form-control " + (errors.company_name ? "is-invalid" : "")
                  }
                  {...register("company_name")}
                />
                <label htmlFor="company_name">Business Name *</label>
              </div>
              {errors.company_name?.message && (
                <div className="row align-items-start text-start mb-3">
                  <span className="span-errors" role="alert">
                    {errors.company_name.message}
                  </span>
                </div>
              )}

              {/* ein  */}
              <div
                className={"row form-floating " + (errors.ein ? "" : "mb-3")}
              >
                <input
                  type="text"
                  id="ein"
                  placeholder="EIN Number"
                  disabled={!edit ? "disabled" : ""}
                  className={"form-control " + (errors.ein ? "is-invalid" : "")}
                  {...register("ein")}
                />
                <label htmlFor="ein">EIN Number *</label>
              </div>
              {errors.ein?.message && (
                <div className="row align-items-start text-start mb-3">
                  <span className="span-errors" role="alert">
                    {errors.ein.message}
                  </span>
                </div>
              )}

              {/* address 1  */}
              <div
                className={
                  "row form-floating " + (errors.address1 ? "" : "mb-3")
                }
              >
                <input
                  type="text"
                  id="address1"
                  placeholder="Address *"
                  disabled={!edit ? "disabled" : ""}
                  className={
                    "form-control " + (errors.address1 ? "is-invalid" : "")
                  }
                  {...register("address1")}
                />
                <label htmlFor="address1">Address *</label>
              </div>
              {errors.address1?.message && (
                <div className=" align-items-start text-start mb-3">
                  <span className="span-errors" role="alert">
                    {errors.address1.message}
                  </span>
                </div>
              )}

              {/* address 2  */}
              <div className={"row form-floating mb-3"}>
                <input
                  type="text"
                  id="address2"
                  placeholder="Address"
                  disabled={!edit ? "disabled" : ""}
                  className="form-control"
                  {...register("address2")}
                />
                <label htmlFor="address2">Address</label>
              </div>

              <div className="row gy-0">
                {/* city   */}
                <div
                  className={
                    "col-6 form-floating g-0 " + (errors.city ? "" : "mb-3")
                  }
                >
                  <input
                    type="text"
                    id="city"
                    placeholder="City *"
                    disabled={!edit ? "disabled" : ""}
                    className={
                      "form-control no-curve-border-right " +
                      (errors.city ? "is-invalid" : "")
                    }
                    {...register("city")}
                  />
                  <label htmlFor="city">City *</label>
                </div>

                {/* state   */}
                <div
                  className={
                    "col-3 form-floating g-0 " + (errors.state ? "" : "mb-3")
                  }
                >
                  <select
                    id="state"
                    placeholder=""
                    disabled={!edit ? "disabled" : ""}
                    className={
                      "form-select no-curve-border " +
                      (errors.state ? "is-invalid" : "")
                    }
                    aria-label="State"
                    defaultValue={selState}
                    {...register("state", {
                      onChange: (e) => {
                        setSelStateStyle("after-select");
                      },
                    })}
                  >
                    <option value=""></option>
                    {statesList.map((x, index) => (
                      <option key={index} value={x.value}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                  <label
                    htmlFor="state"
                    className={"spl-label-select " + selStateStyle}
                  >
                    State *
                  </label>
                </div>

                {/* zip code   */}
                <div
                  className={
                    "col-3 form-floating g-0 " + (errors.zip ? "" : "mb-3")
                  }
                >
                  <input
                    type="text"
                    id="zip"
                    placeholder="Zip Code *"
                    disabled={!edit ? "disabled" : ""}
                    className={
                      "form-control no-curve-border-left " +
                      (errors.zip ? "is-invalid" : "")
                    }
                    {...register("zip")}
                  />
                  <label htmlFor="zip">Zip Code *</label>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  {errors.city?.message && (
                    <div className=" align-items-start text-start mb-3">
                      <span className="span-errors" role="alert">
                        {errors.city.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-3">
                  {errors.state?.message && (
                    <div className=" align-items-start text-start mb-3">
                      <span className="span-errors" role="alert">
                        {errors.state.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-3">
                  {errors.zip?.message && (
                    <div className=" align-items-start text-start mb-3">
                      <span className="span-errors" role="alert">
                        {errors.zip.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              {/* category  */}
              <div
                className={
                  "row form-floating " + (errors.category ? "" : "mb-3")
                }
              >
                <input
                  type="text"
                  id="category"
                  placeholder="Business Category *"
                  disabled={!edit ? "disabled" : ""}
                  className={
                    "form-control " + (errors.category ? "is-invalid" : "")
                  }
                  {...register("category")}
                />
                <label htmlFor="category">Business Category *</label>
              </div>
              {errors.category?.message && (
                <div className=" align-items-start text-start mb-3">
                  <span className="span-errors" role="alert">
                    {errors.category.message}
                  </span>
                </div>
              )}

              {/* company description  */}
              <div className={"row form-floating mb-3"}>
                <textarea
                  id="company_description"
                  style={{ height: "75px" }}
                  placeholder="Business Description"
                  disabled={!edit ? "disabled" : ""}
                  className="form-control"
                  {...register("company_description")}
                  maxLength="128"
                />
                <label htmlFor="company_description">
                  Business Description
                </label>
              </div>

              <div className="row mt-4 mb-2">
                <h4 className="gifter-details-header2">Business contact</h4>
              </div>

              {/* Business contact */}
              <div className="row form-floating mb-3">
                <input
                  type="text"
                  placeholder="Email"
                  disabled={"disabled"}
                  className="form-control"
                  value={emailId}
                />
                <label htmlFor="primary_contact">Email</label>
              </div>
              <div
                className={
                  "row form-floating " + (errors.primary_contact ? "" : "mb-3")
                }
              >
                <input
                  type="text"
                  id="primary_contact"
                  placeholder="Primary Business Contact"
                  disabled={!edit ? "disabled" : ""}
                  className={
                    "form-control " +
                    (errors.primary_contact ? "is-invalid" : "")
                  }
                  {...register("primary_contact")}
                />
                <label htmlFor="primary_contact">
                  Primary Business Contact *
                </label>
              </div>
              {errors.primary_contact?.message && (
                <div className="row align-items-start text-start mb-3">
                  <span className="span-errors" role="alert">
                    {errors.primary_contact.message}
                  </span>
                </div>
              )}

              {/* contact phone*/}
              <div
                className={"row form-floating " + (errors.phone ? "" : "mb-3")}
              >
                <InputMask
                  type="text"
                  id="phone"
                  mask="(999)999-9999"
                  placeholder="Phone Number"
                  autoComplete="on"
                  disabled={!edit ? "disabled" : ""}
                  className={
                    "form-control " + (errors.phone ? "is-invalid" : "")
                  }
                  {...register("phone")}
                />
                <label htmlFor="phone">Phone Number *</label>
              </div>
              {errors.phone?.message && (
                <div className="row align-items-start text-start mb-3">
                  <span className="span-errors" role="alert">
                    {errors.phone.message}
                  </span>
                </div>
              )}

              {/* submit */}
              {edit && (
                <div className="row mb-2 pt-3 text-center">
                  <div className="col-12 my-3">
                    <button
                      type="submit"
                      className="btn btn-light navbar-button"
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              )}
              {error && (
                <div className="row mb-2">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
              {updated && (
                <div className="row mb-2">
                  <div className="alert alert-success" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default CompanyProfileEdit;
