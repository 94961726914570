import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";

import CheckoutForm from "./CheckoutForm";
import "./CCPayment.css";
import {
  createPaymentIntent,
  gifter_stripe_api_key,
} from "../services/Services";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

const stripePromise = loadStripe(gifter_stripe_api_key);

export default function CCPayment() {
  let { business_id, gifter_id, giftee_id, giftcard_id, amount, email } =
    useParams();
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const data = {};
    data["items"] = [{ amount: amount }];
    data["email"] = email;
    data["business_id"] = business_id;
    data["gifter_id"] = gifter_id;
    data["giftee_id"] = giftee_id;
    data["giftcard_id"] = giftcard_id;

    createPaymentIntent(data, signal).then((data) =>
      setClientSecret(data.clientSecret)
    );
    //cleanup function
    return () => {
      controller.abort();
    };
  }, [amount, business_id, email, giftcard_id, gifter_id, giftee_id]);

  const appearance = {
    theme: "flat",
    variables: {
      colorPrimary: "#212529",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
    },
    rules: {
      ".Input": {
        border: "1px solid #E0E6EB",
      },
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            business_id={business_id}
            gifter_id={gifter_id}
            giftee_id={giftee_id}
            giftcard_id={giftcard_id}
            email={email}
          />
        </Elements>
      )}
    </>
  );
}
